import React from 'react';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';

import Session from '../../modules/Session';
import {
  healthCheck,
  login,
  LOGIN_STATES,
  HUBBLE_SESSION_AUTH,
} from '../../modules/Login';
import Workflows from '../../modules/Workflows';
import WorkflowRuns from '../../modules/WorkflowRuns';
import {
  PlanSponsorProvider,
  ParticipantProvider,
  DirectDetailsProvider,
  MFAProvider,
  FundBuilderProvider,
} from '../../modules/admin';
import SignalRProvider from '../../modules/SignalR';

import { Light } from '../../themes/index';
import ReportsProvider from '../../modules/Reports';
import AppView from './App-view';

const App = () => {
  const { loginStatus, token } = Session.get(HUBBLE_SESSION_AUTH) || {};

  if (!loginStatus && !(token && token !== 'undefined')) {
    login();
    return null;
  }
  if (loginStatus === LOGIN_STATES.loggedin) {
    healthCheck();
  }

  return (
    <Workflows>
      <WorkflowRuns>
        <ReportsProvider>
          <PlanSponsorProvider>
            <ParticipantProvider>
              <DirectDetailsProvider>
                <MFAProvider>
                  <SignalRProvider>
                    <FundBuilderProvider>
                      <CssBaseline />
                      <MuiThemeProvider theme={Light}>
                        <AppView />
                      </MuiThemeProvider>
                    </FundBuilderProvider>
                  </SignalRProvider>
                </MFAProvider>
              </DirectDetailsProvider>
            </ParticipantProvider>
          </PlanSponsorProvider>
        </ReportsProvider>
      </WorkflowRuns>
    </Workflows>
  );
};
export default App;
