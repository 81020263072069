import React, { useRef } from 'react';
import { Box, makeStyles, Icon, IconButton, Grow } from '@material-ui/core';
import { Router, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Home from '../Home';
import Workflow from '../Workflow';
import Login from '../Login';
import Oauth from '../Oauth';
import ErrorPage from '../ErrorPage';
import ConfigurePlanSponsor from '../ConfigurePlanSponsor';
import ConfigureFundLineup from '../ConfigureFundLineup';
import { Assist, Queue } from '../MFA';

import RouterHistory from '../../modules/history';

import { ErrorBoundary } from '../../components';
import PlanSponsorReport from '../PlanSponsorReport';

const useStyles = makeStyles((theme) => ({
  snackbars: {
    margin: theme.spacing(0, 1),
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(7),
    },
  },
  snackbaricon: {
    margin: theme.spacing(0, 1, 0, 0),
  },
}));

const AppView = () => {
  const snackBar = useRef(null);
  const classes = useStyles();
  const snackActions = (key) => (
    <IconButton
      color="inherit"
      onClick={() => {
        snackBar.current.handleCloseSnack(null, null, key);
      }}
    >
      <Icon>close</Icon>
    </IconButton>
  );
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={5000}
      action={snackActions}
      className={classes.snackbars}
      iconVariant={{
        info: <Icon className={classes.snackbaricon}>priority_high</Icon>,
        success: <Icon className={classes.snackbaricon}>done</Icon>,
        error: <Icon className={classes.snackbaricon}>priority_high</Icon>,
        warning: <Icon className={classes.snackbaricon}>priority_high</Icon>,
      }}
      maxSnack={5}
      preventDuplicate
      ref={snackBar}
      TransitionComponent={Grow}
    >
      <Box minHeight="100vh" bgcolor="#EEE">
        <Router history={RouterHistory}>
          <Route exact path="/error" component={ErrorPage} />
          <ErrorBoundary>
            <Route exact path="/login" component={Login} />
            <Route exact path="/Oauth" component={Oauth} />
            <Route exact path="/" component={Home} />
            <Route path="/workflow/:id/:runid" component={Workflow} />
            <Route path="/plansponsor/:id" component={ConfigurePlanSponsor} />
            <Route path="/fundlineup/:id" component={ConfigureFundLineup} />
            <Route path="/mfa/queue" component={Queue} />
            <Route path="/mfa/assist/:participantGuid" component={Assist} />
            <Route
              path="/reports/plansponsor/:planSponsorId"
              component={PlanSponsorReport}
            />
          </ErrorBoundary>
        </Router>
      </Box>
    </SnackbarProvider>
  );
};

export default AppView;
