import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import _ from 'lodash';
import Base from '../Base';

const theme = responsiveFontSizes(
  createMuiTheme(
    _.merge(_.cloneDeep(Base), {
      palette: {
        type: 'light',
        common: {
          black: '#323232',
          white: '#FFFFFF',
        },
        primary: {
          main: '#1565C0',
          dark: '#0E437F',
        },
        secondary: {
          main: '#48A54C',
        },
        error: {
          main: '#EE4343',
        },
        background: {
          default: '#FFFFFF',
        },
      },
    })
  )
);

export default theme;
